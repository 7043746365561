import React from 'react';
import AGgridproducts from '../components/AGgridproducts';

const PivotPageProducts = () => {

  return (
      <AGgridproducts />
  );
};

export default PivotPageProducts;