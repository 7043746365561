import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
import KPIChart from '../components/KPIChart';
const cards = [
  {
    title: 'Totaal aantal bestellingen',
    query: {
      measures: ['SalesOrder.count'],
    },
    difference: 'SalesOrder',
  },
  {
    title: 'Totaal aantal unieke klanten',
    query: { measures: ['Users.count'] },
    difference: 'SalesOrder',
  },
  {
    title: 'Totaal omzet',
    query: {
      measures: ['SalesOrder.totalPaid'],
    },
    difference: 'SalesOrder',
  },
];
const DashboardItems = [
  {
    id: 1,
    vizState: {
      query: {
        measures: ["SalesOrderItem.rowInvoiced"],
        timeDimensions: [
          {
            "dimension": "SalesOrder.createdAt",
            "granularity": "day",
            "dateRange": "Last 30 days"
          }
        ],
        filters: [
          {
            member: 'SalesOrder.status',
            operator: 'contains',
            values: ['complete', 'processing'],
          },
        ],
      },
      chartType: 'bar',
    },
  },
];

const DashboardPage = () => {
  const dashboardItem = (item) => (
    <Grid container spacing={4}>
      {cards.map((item, index) => {
        return (
          <Grid key={item.title + index} item lg={3} sm={6} xl={3} xs={12}>
          
          </Grid>
        );
      })}
      <Grid item lg={6} md={12} xl={6} xs={12}>
        <DashboardItem title={item.name}>
          <ChartRenderer vizState={item.vizState} />
        </DashboardItem>
      </Grid>
    </Grid>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12,
      }}
    >
      <Typography variant="h5" color="inherit">
        There are no charts on this dashboard. Use Playground Build to add one...
      </Typography>
    </div>
  );

  return DashboardItems.length ? (
    <Dashboard>
      {cards.map((item, index) => {
        return (
          <Grid key={item.title + index} item lg={4} sm={6} xl={4} xs={12}>
            <KPIChart {...item} />
          </Grid>
        );
      })}
      {DashboardItems.map(dashboardItem)}
    </Dashboard>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
