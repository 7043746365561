import React, { useEffect, useState } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { Layout } from 'antd';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const query = {
  "measures": [
    "SalesOrderItem.rowTotal"
  ],
  "order": [
    [
      "SalesOrder.customerEmail",
      "asc"
    ]
  ],
  "dimensions": [
    "SalesOrder.customerEmail",
    "SalesOrderItem.createdAt"
  ],
  "filters": [
    {
      "member": "SalesOrder.status",
      "operator": "contains",
      "values": [
        "processing",
        "complete"
      ]
    },
    {
      "member": "SalesOrder.customerEmail",
      "operator": "notContains",
      "values": [
        "@verkopen.bol.com"
      ]
    }
  ]
};

const AGgridcustomers = () => {
  const [ rowData, setRowData ] = useState([]);
  const { resultSet } = useCubeQuery(query);

  useEffect(() => {
    if (resultSet) {
      setRowData(resultSet
        .tablePivot()
        .map(row => Object
          .keys(row)
          .reduce((object, key) => ({
            ...object,
            [key.replace('.', '-')]: row[key],
          }), {}),
        ),
      );
    }
  }, [ resultSet ]);

  const columnDefs = [
    ...query.dimensions,
    ...query.measures,
  ].map(field => ({
    headerName: field.split('.')[1],
    field: field.replace('.', '-'),
  }));

  return (
    <Layout>
      <div className='ag-theme-alpine' style={{ width: '100%', height: '50vh' }}>
        <AgGridReact 
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            sortable: true,
            resizable: true,
          }}
          aggFuncs={{
            'min': ({ values }) => values.reduce((min, value) => Math.min(min, Number(value)), 0),
            'max': ({ values }) => values.reduce((max, value) => Math.max(max, Number(value)), 0),
            'sum': ({ values }) => values.reduce((sum, value) => sum + Number(value), 0),
            'avg': ({ values }) => (values.reduce((sum, value) => sum + Number(value), 0) / values.length).toFixed(0),
          }}
          pagination={true}
          animateRows={true}
          paginateChildRows={true}
          sideBar={true}
          paginationAutoPageSize={true}
          rowData={rowData}
        >
          {columnDefs.map((column, i) => {
            const name = column.field.replace('-', '.');
            const isDimension = Object.values(query.dimensions).indexOf(name) !== -1;
            const isMeasure = Object.values(query.measures).indexOf(name) !== -1;

            return (
              <AgGridColumn
              key={i}
              headerName={column.headerName}
              field={column.field}
              enableRowGroup={isDimension}
              enableValue={isMeasure}
              filter='agMultiColumnFilter'
              allowedAggFuncs={[ 'sum', 'max', 'avg', 'min' ]}
              aggFunc={isMeasure ? 'sum' : null}
              />
            );
          })}
        </AgGridReact>
      </div>
    </Layout>
  );
};

export default AGgridcustomers;