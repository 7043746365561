import './body.css';
import React, { useEffect, useState, useCallback } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { CubeProvider } from '@cubejs-client/react';
import client from './graphql/client';
import { Main } from './layouts'
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import theme from './theme';
import 'typeface-roboto'
import palette from "./theme/palette";
import { initCubejsApi } from './init-cubejs-api';
import {LicenseManager} from "ag-grid-enterprise";
import { hotjar } from 'react-hotjar';
LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-7_April_2022_[v2]_MTY0OTI4NjAwMDAwMA==bb014e7734204673fe4a48c28d6c8645");

hotjar.initialize(1670825,6);

// Identify the user
hotjar.identify('USER_ID', { userProperty: 'value' });

// Add an event
hotjar.event('button-click');


const AppLayout = ({children}) => {
  const classes = useStyles();
  return (
  <ThemeProvider theme={theme}>
    <Main>
      <div className={classes.root}>
        <div>{children}</div>
      </div>
    </Main>
      </ThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '-8px',
    backgroundColor: palette.primary.light,
  },
}));

const App = ({ children }) => {
  const [ cubejsApi, setCubejsApi ] = useState(null);

  // Get all necessary auth0 data
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    user
  } = useAuth0();

  console.log('===');
  console.log({
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    user
  });

  // Force to work only for logged in users bye checking isAuthenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Redirect not logged users
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  // Get CubeJS instance with access_token and set to component state
  const initCubejs = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "openid profile email",
    });

    console.log(accessToken);

    setCubejsApi(initCubejsApi(accessToken));
  }, [getAccessTokenSilently]);

  // Init CubeJS instance with access_token
  useEffect(() => {
    if (!cubejsApi && !isLoading && isAuthenticated) {
      initCubejs();
    }
  }, [ cubejsApi, initCubejs, isAuthenticated, isLoading ]);

  if (error) {
    return <span>{error.message}</span>;
  }

  // show loading indicator while loading
  if (isLoading || !isAuthenticated || !cubejsApi) {
    return <span>Loading</span>;
  }

  return <CubeProvider cubejsApi={cubejsApi}>
    <ApolloProvider client={client}>
      <AppLayout>{children}</AppLayout>
    </ApolloProvider>
  </CubeProvider>;
}

export default App;