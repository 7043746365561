import React from 'react';
import AGgridcustomers from '../components/AGgridcustomers';

const PivotPageOrders = () => {

  return (
      <AGgridcustomers />
  );
};

export default PivotPageOrders;